<template>
  <div class="param-table">
    <div class="top-param">
      <div
        class="left-tab"
        :class="{ 'select-tab': tableIndex == 0 }"
        @click="changeTable(0)"
      >
        Technical Characteristics
      </div>
      <!-- <div
        class="right-tab"
        :class="{ 'select-tab': tableIndex == 1 }"
        @click="changeTable(1)"
      >
        Basic parameters
      </div> -->
    </div>
    <div class="bot-table">
      <div
        class="item"
        v-for="(item, index) in showTable"
        :class="index % 2 != 0 ? 'item-h' : ''"
      >
        <div class="left-para" :class="{ 'left-basic': tableIndex == 1 }">
          {{ item[0] }}
        </div>
        <div class="right-para" :class="{ 'right-basic': tableIndex == 1 }">
          {{ item[1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableIndex: 0,
    };
  },
  computed: {
    tableData1() {
        let data=[
        ['Item','QC4.10-cds'],
        ['Rated Voltage','IEC1500V/UL1500V'],
        ['Rated Current(IEC/UL)','36A 41A 46A 55A /30A 35A 50A'],
        ['Size of Cables','2.5mm2,4.0mm2,6.0mm2,10.0mm2/14AWG,12AWG,10AWG'],
        ['Protection Class','Ⅱ'],
        ['Temperature Range',' -40°C~+85°C'],
        ['Contact Resistance',' <5mΩ'],
        ['Protection Degree','IP68'],
        ['Flammability Class','5VA']
        //   ["Usable Energy*", res[1], true],
        // ["Battery Module", res[2], false],
        // ["Number of Modules", res[3], true],
        // ["Nominal Voltage", res[4], true],
        // ["Operating Voltage Ranges", res[5], true],
        // ["Nominal Dis-/Charge Current", res[6], false],
        // ["Max. Charge Voltage", res[7], true],
        // ["Weight", res[8], true],
        // ["Dimension(mm)", res[9], true],
        // ["Safety", res[10], false],
      ]
      return data;
    },
    basicInfo() {
      let data = [];

      if (this.$store.state.meshType == "LFP 3000/HV") {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor or outdoor"],
          ["Operating Charge", "0~55℃"],
          ["Temperature Discharge", "-20~55℃"],
          ["Humidity", "0~95% (No condensed water)"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Naturl cooling"],
          ["Case Material", "Metal+Plastic"],
          ["IP Rating", "IP 54"],
          ["Protective Class", "I"],
          ["Warranty", "10 years"],
          ["Life Span", "＞15 years"],
          ["Communication", "CAN/WIFI"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short-circuit/Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor or outdoor"],
          ["Operating Charge", "From 0~45℃"],
          ["Temperature Discharge", "From -10~50℃"],
          ["Humidity", "4~100%（No condensed water）"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Natural cooling"],
          ["Case Material", "Metal+Plastic"],
          ["IP rating", "IP 65"],
          ["Protective Class", "I"],
          ["Max. Number of Parallel", "16"],
          ["Warranty", "10 years"],
          ["Life Span", ">15 years"],
          ["Communication", "CAN/RS485"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short circuit/ Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      } else {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor"],
          ["Operating Charge", "From 0~45℃"],
          ["Temperature Discharge", "From -10~50℃"],
          ["Storage Temperature", "From -20~50℃"],
          ["Humidity", "4~100% (No condensed water)"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Natural cooling"],
          ["Case Material", "Metal"],
          ["IP rating", "IP20"],
          ["Protective Class", "I"],
          ["Max. Number of Parallel", "16"],
          ["Warranty", "10 years"],
          ["Life Span", ">15 years"],
          ["Communication", "CAN/RS485"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short circuit/ Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      }

      return data;
    },
    showTable() {
      if (this.tableIndex == 1) {
        return this.basicInfo;
      } else {
        return this.tableData1;
      }
    },
  },
  methods: {
    changeTable(index) {
      this.tableIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.param-table {
  width: 100%;
  height: 100%;
  .top-param {
    width: 343px;
    height: 43px;
    display: flex;
    align-items: center;
    .left-tab,
    .right-tab {
      height: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      border-bottom: 3px solid transparent;
    }
    .left-tab {
      // width: 125px;
    }
    .right-tab {
      width: 153px;
      margin-left: 32px;
    }
    .select-tab {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      // border-bottom: 3px solid rgb(189,201,117);
    }
  }
  .bot-table {
    .item-h {
      background: #f0f0f0;
    }
    .item {
      width: 343px;
      min-height: 28px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
    .left-para,
    .right-para {
      padding-left: 12px;
      color: rgba(0, 0, 0, 0.8);
      word-break: break-all;
    }
    .left-para {
      width: 174px;
    }
    .right-para {
      width: 169px;
      color: rgba(0, 0, 0, 0.6) !important;
    }
    .left-basic {
      width: 160px;
    }
    .right-basic {
      width: 183px;
    }
  }
}
</style>
