<template>
  <div class="img-content">
    <div class="top-con">
      <!-- <img :src="imgList[shareIndex]" alt="" /> -->
      <img src="../../../assets/project.jpg" alt="" />
    </div>
    <div class="center-link">
      <div class="left-link">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="right-link" ref="qrCodeUrl"></div>
    </div>
    <div class="bot-text">
      <!-- <div class="text" v-for="item in featureData"> -->
      <!-- <div class="text-icon"> -->
      <!-- <img :src="item.img" alt="" /> -->
      <!-- </div> -->
      <!-- <p v-for="content in item.datas"> -->
      <!-- {{ content }} -->
      <!-- </p> -->
      <!-- </div> -->
      <p>
        They can take on heavy responsibilities at the critical moment,can't be
        stopped by neither wind nor rain for many years.Multifactor Designs of
        structure are to meet different customer needs,Higher transfer
        capability for the current and lower contact resistance make much more
        profits.IP68 Waterproof class can make you feel safe in any bad
        environment.Approved by TUV and UL, and universally accepted. QC Solar,
        innovation everywhere
      </p>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      imgList: [
        require("@/assets/dialog/first.png"),
        require("@/assets/dialog/second.png"),
        require("@/assets/dialog/thrid.png"),
      ],
      meshMap: ["LFP 3000/HV", "LFP 5-10KWH/LV", "LFP 5000"],
      tabMap: ["Features", "Parameter", "Structure"],
    };
  },
  computed: {
    shareIndex() {
      return this.meshMap.findIndex(
        (item) => item === this.$store.state.meshType
      );
    },
    featureData() {
      let data;
      if (this.$store.state.meshType == "LFP 3000/HV") {
        data = [
          {
            img: require("@/assets/features/1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/2.svg"),
            datas: ["Home Automation"],
          },
          {
            img: require("@/assets/features/3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/5.svg"),
            datas: ["Communication"],
          },
          {
            img: require("@/assets/features/6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/8.svg"),
            datas: ["IP54 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          {
            img: require("@/assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("@/assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/1-5.svg"),
            datas: ["Scalable up to 160kWh 16 (Parallel)"],
          },
          {
            img: require("@/assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/1-9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      } else {
        data = [
          {
            img: require("@/assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("@/assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/1-5.svg"),
            datas: ["Scalable up to 80kWh 16 (Parallel)"],
          },
          {
            img: require("@/assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/1-9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      }

      return data;
    },
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    creatQrCode() {
      let width = this.$refs.qrCodeUrl.offsetWidth;
      let height = this.$refs.qrCodeUrl.offsetHeight;
      let nowHref = window.location.origin;
      let res1 = this.$store.state.selectName.replaceAll(" ", "");
      let res = res1.replace("·", "-");
      new QRCode(this.$refs.qrCodeUrl, {
        text: `${nowHref}/#/`, // 二维码的内容
        width,
        height,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.L,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-con {
    width: 100%;
    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      rgba(247, 248, 250, 0.7) 100%
    );
    // border-radius: 6px;
    img {
      width: 100%;
    }
    flex: 1;
  }
  .center-link {
    margin-top: 7px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .left-link {
      width: 129px;
      height: 28px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .right-link {
      width: 64px;
      height: 64px;
    }
  }
  .bot-text {
    margin-top: 6px;
    width: 100%;
    height: 138px;
    flex: 1;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);

    .text {
      display: flex;
      margin-top: 10px;
      .text-icon {
        width: 15px;
        height: 15px;
        margin-right: 9px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
